/* .tab {
    font-size: 14px;
    font-family: PlusJakartaSans-Regular, sans;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.tab.active{
    font-family: PlusJakartaSans-Regular, sans;
    font-size: 14px;
    --tw-bg-opacity: 1;
    background-color: rgb(231 123 48 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
} */

.content{
    display: none;
}

.content.active{
    display: block;    
}
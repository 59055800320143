*{
  margin: 0;
  padding: 0;
}

:root:has(.no-doc-scroll){
  overflow:hidden;
}

.nav{
  background-color: transparent;
}

.nav.recolored{
  background-color: #fff;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.navbar{
  background-color: transparent;
}

.navbar.colorChange{
  background-color: #fff;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.act-btn{
  width: 100px;
  height: 100px;
  line-height: 43px;
  align-items: center;
  justify-content: center;
  color: #e77B30;
  font-size: 25px;
  font-weight: bold;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  text-decoration: none;
  transition: ease all 0.3s;
  position: fixed;
  right: 10px;
  bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .act-btn {
    width: 135px;
    height: 135px;
    right: 30px;
  }
}

/* @media screen and (min-width: 1750px) {
  .act-btn {
    width: 135px;
    height: 135px;
    right: 275px;
  }
} */

html{
  scroll-behavior:smooth
}

.outline-title {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 3px black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* .act-btn{
  background: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 43px;
  align-items: center;
  justify-content: center;
  color: #e77B30;
  font-size: 25px;
  font-weight: bold;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  text-decoration: none;
  transition: ease all 0.3s;
  position: fixed;
  right: 30px;
  bottom: 30px;
} */
.container{
    width: 350px;
    min-height: 300px;
    border-radius: 1rem/* 16px */;
    padding: 1rem/* 16px */;
    justify-items: center;
    align-content: center;
}

.container img{
    width: auto;
    height: 150px;
    object-fit: contain;
    margin-bottom: 0.75rem/* 12px */;
}

.swiper-slide{
    width: auto
}

.swiper-pagination-nolimit{
	margin-bottom: 1.3rem;
	z-index: 0;
}

.swiper-pagination-nolimit .swiper-pagination-bullet{
	padding: 5px 5px;
	border-radius: 10px;
	width: auto;
	height: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	color:#d5521b;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}

.swiper-pagination-nolimit .swiper-pagination-bullet-active {
	opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #e77b30;
}

.swiper-pagination-nolimit2{
	margin-bottom: 1.3rem;
	z-index: 0;
}

.swiper-pagination-nolimit2 .swiper-pagination-bullet{
	padding: 5px 5px;
	border-radius: 10px;
	width: auto;
	height: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	color:#d5521b;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}

.swiper-pagination-nolimit2 .swiper-pagination-bullet-active {
	opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #e77b30;
}

.test {
	box-shadow:  inset 0px 10px 20px 2px #3a86ff;
}
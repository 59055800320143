.nav {
    background: transparent;
    width: 100%;
    height: 80px;
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    position: fixed;
    z-index: 40;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1000ms;
    padding-left: 0.5rem/* 2px */;
    padding-right: 0.5rem/* 2px */;
}

@media screen and (min-width: 1024px) {
    .nav {
        padding-left: 2rem/* 8px */;
        padding-right: 2rem/* 8px */;
    }
}

@media screen and (min-width: 1280px) {
    .nav {
        padding-left: 2.5rem/* 40px */;
        padding-right: 2.5rem/* 40px */;
    }
}

.image-container {
    display: flex;
    width: fit-content;
    height: fit-content;
    align-items: center;
    gap: 0.75rem/* 12px */;
}

.nav ul {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: grid;
    place-content: stretch;
    /*gap: 1rem/* 40px */;
    top: 0;
    /* bottom: 0; */
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1000ms;
    /*text-align: center;*/
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media screen and (min-width: 1280px) {
    .nav ul {
        width: auto;
        height: auto;
        position: static;
		display: inline-flex;
        align-items: center;
        background-color: transparent;
        --tw-bg-opacity: 0;
        --tw-backdrop-blur: blur(0);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        --tw-drop-shadow: drop-shadow(0 0 #0000);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
        gap: 1.25rem/* 20px */;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.nav li.active {
    font-family: PlusJakartaSans-ExtraBold, sans;
    --tw-bg-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}

@media screen and (min-width: 1024px) {
    --tw-bg-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}

.nav a {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.menu {
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    .menu {
        display: inline-flex;
        font-size: 21px;
    }
}

@media screen and (min-width: 1280px) {
    .menu {
        display: none; 
    }
}

.arrow{
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
}

.arrow-2{
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
}
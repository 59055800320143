input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.css-10v7i6a-MuiStepLabel-label{
  font-size: 14px !important;
}

.css-57xvua-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #F4BA54 !important;
}

.css-1xdkqsq-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #F4BA54 !important;
}

.css-hxtt6b-MuiPickersMonth-monthButton {
  font-size: 13px !important;
}

.css-hxtt6b-MuiPickersMonth-monthButton.Mui-selected {
  background-color: #F4BA54 !important;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  font-size:  14px !important;
}

.css-1rfebap-MuiPickersYear-yearButton {
  font-size:  13px !important;
}

.css-1rfebap-MuiPickersYear-yearButton.Mui-selected {
  background-color: #F4BA54 !important;
}
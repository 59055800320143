.tabs {
    font-size: 14px;
    font-family: PlusJakartaSans-Regular, sans;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tabs.active{
    font-family: PlusJakartaSans-Bold, sans;
    border-bottom-width: 4px;
    --tw-border-opacity: 1;
    border-color: rgb(32 74 99 / var(--tw-border-opacity));
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.contents{
    display: none;
}

.contents.active{
    display: block;    
}
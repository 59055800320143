.swiper-pagination{
	/* margin-bottom: 1.3rem; */
	z-index: 0;
}

.swiper-pagination-bullet{
	padding: 5px 5px;
	/* border-radius: 0; */
	border-radius: 100%;
	width: auto;
	/* height: 30px; */
	height: auto;
	text-align: center;
	/* line-height: 30px; */
	font-size: 12px;
	color:#000;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}

.swiper-pagination .swiper-pagination-bullet-active{
	opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #fff;
}
.toggle-container{
    display: flex;
    align-items: center;
    position: relative;
    width: max-content;
    cursor: pointer;
    user-select: none;
}

.toggle-container input{
    appearance: none;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    cursor: pointer;
    width: 3rem/* 48px */;
    height: 1.5rem/* 24px */;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    /* background-color: rgb(209 213 219 / var(--tw-bg-opacity)); */
    background-color: rgb(234 234 234 / var(--tw-bg-opacity));
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
    background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.title{
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    /* color: rgb(255 255 255 / var(--tw-text-opacity)); */
    color: rgb(79 79 79 / var(--tw-text-opacity));
    font-family: PlusJakartaSans-Regular, sans;
}

.indicator{
    width: 1.25rem/* 20px */;
    height: 1.25rem/* 20px */;
    right: 26px;
    position: absolute;
    border-radius: 9999px;
    box-shadow: 1.5px 1.5px 3px grey;
}
.swiper-pagination-three{
	margin-bottom: 1.3rem;
	z-index: 0;
}

.swiper-pagination-three .swiper-pagination-bullet{
	padding: 5px 5px;
	border-radius: 10px;
	width: auto;
	height: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	color:#d5521b;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}

.swiper-pagination-three .swiper-pagination-bullet-active {
	opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #ffffff;
}

@media (min-width: 2560px) {
    .high-screen {
        left: 465px;
    }
}
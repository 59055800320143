@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "PlusJakartaSans-Light";
    src: url("./assets/fonts/PlusJakartaSans-Light.ttf");
}

@font-face {
    font-family: "PlusJakartaSans-Regular";
    src: url("./assets/fonts/PlusJakartaSans-Regular.ttf");
}

@font-face {
    font-family: "PlusJakartaSans-Bold";
    src: url("./assets/fonts/PlusJakartaSans-Bold.ttf");
}

@font-face {
    font-family: "PlusJakartaSans-ExtraBold";
    src: url("./assets/fonts/PlusJakartaSans-ExtraBold.ttf");
}

@font-face {
    font-family: "custom-regular";
    src: url("./assets/fonts/PlusJakartaSans-Regular.ttf");
}

@font-face {
    font-family: "custom-italic";
    src: url("./assets/fonts/PlusJakartaSans-Italic.ttf");
}
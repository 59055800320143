.swiper-pagination-two{
	margin-bottom: 1.3rem;
	z-index: 0;
}

.swiper-pagination-two .swiper-pagination-bullet{
	padding: 5px 5px;
	border-radius: 10px;
	width: auto;
	height: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	color:#000;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}

.swiper-pagination-two .swiper-pagination-bullet-active {
	opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #e77b30;
}

.tab {
    font-size: 14px;
    font-family: PlusJakartaSans-Regular, sans;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.tab.active{
    font-family: PlusJakartaSans-Regular, sans;
    font-size: 14px;
    --tw-bg-opacity: 1;
    background-color: rgb(231 123 48 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
	.tab {
		font-size: 14px;
	}

	.tab.active{
	    font-size: 14px;
	}
}
.slider{
    width: auto;
    height: 100px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    overflow: hidden;
    position: relative;
}

.slider .slide-track{
    animation: scroll 10s linear infinite;
    display: flex;
    width: calc(250px * 28)
}

.slider .slide{
    width: 250px;
    height: 75px;
    display: flex;
    object-fit: cover;
    align-items: center;
    justify-content: center;
}

@keyframes scroll{
    0%{
    transform: translateX(0px);
    }

    100%{
    transform: translateX(calc(-250px * 7));
    }
}
